import { toast } from "react-toastify"
import { API_URL } from "./config"


export const saveImage = async ({ file, token, ratio }) => {    
  const formData = new FormData() 
   formData.append("image", file)
   const res = await fetch(`${API_URL}/api/image?ratio=${ratio}`, {
     method: "POST",
     headers: {
       enctype: "multipart/form-data",
       authorization: `Bearer ${token}`,
     },
     body: formData,
   })
   const data = await res.json()

   if (!res.ok) {
     toast.error(data.message)
     return ''
   } else {
     
     return {...data}
   }
}

export const deleteImage = async ({pathLg,pathMd,pathSm,token}) => {
  const res = await fetch(`${API_URL}/api/image`, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ pathLg,pathMd, pathSm }),
  })
  const data = await res.json()
  
  if (!res.ok) {
    toast.error(data.message)
    return {ok:false}
  } else {
    return {ok:true}
  }
}
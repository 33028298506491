import AccessDenied from "../components/AccessDenied"
import AuthContext from "../context/AuthContext"
import React, { useContext } from "react"
import Links from "../components/Links"
import { Helmet } from "react-helmet-async"
import { useUsersList } from "../swr"

export default function UsersList() {
  const { user } = useContext(AuthContext)
  const { isAdmin, token } = user

  const users = useUsersList(token)

  return (
    <>
      <Helmet>
        <title>Информация о доставке</title>
        <meta
          name="description"
          content="Информация о доставке клиента магазина Кармен"
        />
      </Helmet>

      <div className="px-2">
        {isAdmin ? (
          <>
            <Links home={true} back={true} />

            <div className="py-1 w-full max-h-[600px] overflow-auto pt-4">
              <table className="table-fixed w-full min-w-[1024px] border-collapse border border-gray-600 text-lg">
                <thead>
                  <tr className="text-center">
                    <th className="border border-gray-600" rowSpan="2">
                      Ник
                    </th>
                    <th className="border border-gray-600" rowSpan="2">
                      Телефон
                    </th>
                    <th className="border border-gray-600" colSpan="5">
                      Доставка
                    </th>
                  </tr>
                  <tr className="text-center">
                    <th className="border border-gray-600">Имя</th>
                    <th className="border border-gray-600">Фамилия</th>
                    <th className="border border-gray-600">Телефон</th>
                    <th className="border border-gray-600">Город</th>
                    <th className="border border-gray-600">Перевозчик</th>
                  </tr>
                </thead>
                <tbody>
                  {users ? (
                    users.map((item, i) => (
                      <tr
                        className="text-center odd:bg-gray-50 even:bg-gray-200"
                        key={i}
                      >
                        <td className="border border-gray-600">
                          {item.userName}
                        </td>
                        <td className="border border-gray-600">{item.phone}</td>
                        <td className="border border-gray-600">
                          {item.delivery.name}
                        </td>
                        <td className="border border-gray-600">
                          {item.delivery.surname}
                        </td>
                        <td className="border border-gray-600">
                          {item.delivery.phone}
                        </td>
                        <td className="border border-gray-600">
                          {item.delivery.city}
                        </td>
                        <td className="border border-gray-600">
                          {item.delivery.carrier + " " + item.delivery.branch}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <AccessDenied />
        )}
      </div>
    </>
  )
}

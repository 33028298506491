
import { API_URL } from "../config/index"
import { useContext } from "react"
import AuthContext from "../context/AuthContext"
import { toast, ToastContainer } from "react-toastify"

import Links from "../components/Links"
import { useNavigate } from "react-router-dom"
import AccessDenied from "../components/AccessDenied"
import { FaEye, FaTimes } from "react-icons/fa"
import { useOrder } from "../swr"

import { useSWRConfig } from "swr"
import { formatingDate } from "../utils"
import { Helmet } from "react-helmet-async"

export default function OrderAdminList() {
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  const { mutate } =useSWRConfig()
 const token=user.token  
  
  const orders = useOrder(token)

  const openOrder = (order) => {
    navigate(`/order_page/${order._id}`)
  }
  const deleteOrder = async ( order ) => {
    const res = await fetch(`${API_URL}/api/order/delete/${order._id}`, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${user.token}`,
      },
    })
    if (res.ok) {
      
      mutate([`${API_URL}/api/order`,token])
    } else {
      toast.error("Не удалось удалить заказ")
    }
  }
  return (
    <>
      <Helmet>
        <title>Заказы клиентов</title>
        <meta name="description" content="Заказы клиентов магазина Кармен" />
      </Helmet>
      <ToastContainer />
      {Object.keys(user) && user.isAdmin ? (
        <div className="px-2">
          <Links home={true} back={true} />
          <div className="pt-4 max-h-[500px] overflow-auto ">
            <table
              className="border-collapse border border-gray-600 w-full 
            min-w-[1024px] table-fixed text-lg "
            >
              <thead>
                <tr className=" bg-amber-50 text-center">
                  <th className=" border border-gray-600 p-1 w-[50px] ">№</th>
                  <th className=" border border-gray-600 p-1 w-[150px]">
                    Дата
                  </th>
                  <th className=" border border-gray-600 p-1">Клиент</th>
                  <th className=" border border-gray-600 p-1">Город</th>
                  <th className=" border border-gray-600 p-1 w-[250px]">
                    Общая сумма
                  </th>
                  <th className=" border border-gray-600 p-1 w-[50px]">
                    &nbsp;
                  </th>
                  <th className=" border border-gray-600 p-1 w-[50px]">
                    &nbsp;
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders
                  ? orders.map((item, i) => (
                      <tr
                        className="odd:bg-gray-50 even:bg-gray-200 text-center"
                        key={i}
                      >
                        <td>{item.count}</td>
                        <td>{formatingDate(item.createdAt)}</td>
                        <td>
                          {item.delivery.name ? item.delivery.name : null}&nbsp;
                          {item.delivery.surname ? item.delivery.surname : null}
                        </td>
                        <td>
                          {item.delivery.city ? item.delivery.city : null}
                        </td>
                        <td>{item.totalAmount}</td>
                        <td title="Смотреть заказ">
                          <FaEye
                            className="inline-block text-xl text-sb cursor-pointer"
                            name="look"
                            onClick={() => openOrder(item)}
                          />
                        </td>
                        <td title="Удалить заказ">
                          <FaTimes
                            className="inline-block text-xl text-red-500 cursor-pointer"
                            name="delete"
                            onClick={() => deleteOrder(item)}
                          />
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  )
}

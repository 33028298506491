import React, { useContext, useState } from "react"
import { API_URL, NOIMAGE } from "../config"
import Links from "../components/Links"
import AuthContext from "../context/AuthContext"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AccessDenied from "../components/AccessDenied"
import { getListForCatalogsMenu } from "../utils/index"
import ModalImage from "../components/ModalImage"
import { FaImage, FaSave, FaTimes, FaWindowClose } from "react-icons/fa"
import ModalDialog from "../components/ModalDialog"
import { useSWRConfig } from "swr"
import { useCatalogs } from "../swr"
import { useNavigate } from "react-router-dom"
import { saveImage } from "../handleImage"
import { Helmet } from "react-helmet-async"
import BrandContext from "../context/BrandContext"

export default function AddCatalog() {
  const {
    user: { isAdmin, token },
  } = useContext(AuthContext)
   const { brand } = useContext(BrandContext)
  const { mutate } = useSWRConfig()

  const navigate = useNavigate()

  const [image, setImage] = useState({
    pathMd: "",
    pathSm: "",
  })

  const [values, setValues] = useState({
    name: "",
    parent: "",
    parentId: null,
  })
  const [showImageUpload, setShowImageUpload] = useState(false)

  const catalogs = useCatalogs()
  const listForMenu = catalogs ? getListForCatalogsMenu(catalogs) : []

  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target

    setValues({ ...values, [name]: value })
  }

  const handleListClick = async ({ id, name }) => {
    // проверяем наличие товаров в родительском каталоге
    const res = await fetch(`${API_URL}/api/products/catalog?catalogId=${id}`)
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
    } else {
      const { products } = data     
      if (products.length) {
        toast.warning("В этом каталоге есть товары, выберите другой каталог")
        return
      }
      setValues({ ...values, parent: name, parentId: id })
    }
  }

  const handleUploadChange = async (e) => {
    const file = e.target.files[0]
    const { pathMd, pathSm } = await saveImage({ file, token })
    setImage({ pathMd, pathSm })
    setShowImageUpload(false)
  }
  const deleteImage = () => {
    setImage({ pathMd: "", pathSm: "" })
  }

  const handleSave = async () => {
    // Проверка на заполнение поля имени категории
    const hasEmptyFields = !values.name.trim()
    if (hasEmptyFields) {
      toast.error("Поле Каталог должно быть заполнено")
      return
    }
    const res = await fetch(`${API_URL}/api/catalogs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...values, image }),
    })
    const data = await res.json()

    if (!res.ok) {
      toast.error(data.message)
    } else {
      mutate(`${API_URL}/api/catalogs`)
      mutate(`${API_URL}/api/catalogs/brand/${brand._id}`)
      navigate("/")
    }
  }

  return (
    <>
      <Helmet>
        <title>Добавить каталог</title>
        <meta
          name="description"
          content="Добавление каталога в машазин Кармен"
        />
      </Helmet>
      {!isAdmin ? (
        <AccessDenied />
      ) : (
        <div className="px-2 text-lg">
          <ToastContainer />
          <div className="block">
            <Links home={true} back={true}>
              <div className="flex justify-end items-center py-1 text-3xl">
                <FaSave
                  className="text-sb  hover:cursor-pointer hover:text-sb-dark"
                  title="Сохранить"
                  onClick={handleSave}
                />
              </div>
            </Links>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 pt-4">
              <div className="justify-self-center w-[300px]">
                <div
                  className="w-[300px] h-[400px] flex justify-center items-center
                 border border-gray-700 p-1"
                >
                  <img
                    className="max-w-full max-h-full"
                    src={image.pathMd ? `${API_URL}${image.pathMd}` : NOIMAGE}
                    alt=""
                  />
                </div>

                <div className="flex text-3xl justify-around w-full my-2">
                  <FaImage
                    className="flex cursor-pointer  text-sb  "
                    onClick={() => {
                      setShowImageUpload(true)
                    }}
                    name="save"
                    title="Сохранить"
                  />
                  <FaWindowClose
                    className="flex cursor-pointer text-red-500"
                    onClick={deleteImage}
                    name="delete"
                    title="Удалить"
                  />
                </div>
              </div>
              <div>
                <input
                  className="block w-full h-8 mb-5 px-2 py-1 text-lg"
                  type="text"
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Каталог"
                />

                <div className="relative group">
                  <input
                    className="block w-full h-8 mb-5 px-2 py-1 text-lg"
                    type="text"
                    id="parent"
                    name="parent"
                    value={values.parent}
                    onChange={handleChange}
                    placeholder="Родительский каталог"
                    readOnly
                    autoComplete="off"
                  />
                  <FaTimes
                    className="block -translate-x-1/2 -translate-y-1/2 text-5
                       absolute text-red-500 top-1/2 right-0 hover:cursor-pointer"
                    onClick={() =>
                      setValues({
                        ...values,
                        parent: "",
                        parentId: null,
                      })
                    }
                  />
                  <ul className="droplist_wrapper group-hover:block">
                    {listForMenu.length && (
                      <div className=" droplist">
                        {listForMenu.map((item, i) => (
                          <li
                            className="px-3 hover:cursor-pointer hover:bg-gray-100"
                            key={i}
                            onClick={() =>
                              handleListClick({
                                id: item.cat._id,
                                name: item.cat.name,
                              })
                            }
                          >
                            {item.tree}
                          </li>
                        ))}
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showImageUpload ? (
        <ModalDialog>
          <ModalImage
            handleUploadChange={handleUploadChange}
            setShowImageUpload={setShowImageUpload}
          />
        </ModalDialog>
      ) : null}
    </>
  )
}

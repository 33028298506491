import React, { useContext } from "react"
import Hyphenated from "react-hyphen"
import ru from "hyphenated-ru"


import { API_URL, NOIMAGE } from "../config"

export default function CardNews({ product, setIsShow, setImage }) {
  return (
    <div>
      <div
        className="flex flex-col items-center gap-1 text-xl/6  p-1 w-[300px] h-[450px]
       border border-gray-500 shadow-md"
      >
        <div className="flex justify-center items-center w-[290px] h-[330px] border border-gray-500">
          <img
            className="max-w-full max-h-full hover:cursor-zoom-in"
            src={
              product.imageMd ? `${API_URL}${product.imageMd}` : `${NOIMAGE}`
            }
            onClick={(e) => {
              e.stopPropagation()
              setIsShow(true)
              setImage(product.image)
            }}
          />
        </div>
        <h3 className="indent-6 pb-1 flex-1  text-lg/5 text-gray-700 overflow-y-auto">
          <Hyphenated language={ru}>{product.description}</Hyphenated>     
        </h3>
      </div>
    </div>
  )
}

import { API_URL } from "../config"
import { useState} from "react"
import  { createPortal } from "react-dom"
import {
 
  FaChevronLeft,
  FaChevronRight,
  FaTimes,
} from "react-icons/fa"

export default function Slider({
  images,
  setSliderValues,
  sliderValues,
  setMainImageIdx,
}) {
  const [num, setNum] = useState(sliderValues.idx)

  const lastNumber = images.length - 1
  const prevImage = () => {
    if (num === 0) {
      setNum(lastNumber)
    } else {
      setNum(num - 1)
    }
  }

  const nextImage = () => {
    if (num === lastNumber) {
      setNum(0)
    } else {
      setNum(num + 1)
    }
  }

  const closeSlider = (idx) => {
    setMainImageIdx(idx)
    setSliderValues({ ...sliderValues, isShow: false })
  }
  const imageHeight = window.innerHeight * 0.95

  const content = (
    <div className="z-[150] w-full h-full fixed top-0 left-o bg-[rgb(0,0,0,0.9)]">
      <div className="flex justify-center items-center w-full relative h-screen">
        {lastNumber ? (
          <FaChevronLeft
            onClick={prevImage}
            className="absolute top-[50vh] -translate-x-1/2 -translate-y-1/2 left-4
             text-2xl flex text-white bg-inherit border-inherit hover:cursor-pointer "
          />
        ) : null}
        <div
          className="flex items-center max-w-[85%] cursor-pointer"
          onClick={() => closeSlider(num)}
        >
          <img
            className="max-w-full"
            src={`${API_URL}${images[num]}`}
            style={{ maxHeight: imageHeight }}
          />
        </div>

        {lastNumber ? (
          <FaChevronRight
            onClick={nextImage}
            className="absolute top-[50vh] translate-x-1/2 -translate-y-1/2 right-4
             text-2xl flex text-white bg-inherit border-inherit hover:cursor-pointer "
          />
        ) : null}
      </div>
      <FaTimes
        onClick={() => closeSlider(num)}
        className="text-white absolute top-3 right-3 text-3xl hover:cursor-pointer"
      />
    </div>
  )

  return createPortal(content, document.body)
}

import React, { useContext, useState } from "react"
import { useOptions } from "../swr"
import Links from "../components/Links"
import { FaPencilAlt, FaSave, FaTimes } from "react-icons/fa"
import Options from "../components/Options"
import { ToastContainer, toast } from "react-toastify"
import { API_URL } from "../config"
import { useSWRConfig } from "swr"
import AuthContext from "../context/AuthContext"
import HandleConfirm from "../components/HandleConfirm"
import AccessDenied from "../components/AccessDenied"
import Spinner from "../components/Spinner"
import { Helmet } from "react-helmet-async"

export default function EditOptions() {
  const { mutate } = useSWRConfig()
  const {
    user: { isAdmin, token },
  } = useContext(AuthContext)

  const options = useOptions()
  const initValues = {
    _id: null,
    name: "",
    options: {},
  }
  const [values, setValues] = useState(initValues)
  const [showModal, setShowModal] = useState(false)
  const [optForDelete, setOptForDelete] = useState({})
  const handleEdit = async (item) => {
    const res = await fetch(`${API_URL}/api/options/${item._id}`)
    const data = await res.json()

    if (!res.ok) {
      toast.error(data.message)
      return
    }
    const {
      options: { _id, name, options },
    } = data
    setValues({ ...values, _id, name, options })
  }

  const handleModal = (item) => {
    setOptForDelete(item)
    setShowModal(true)
  }

  const handleDelete = async (item) => {
    const res = await fetch(`${API_URL}/api/options/${item._id}`, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
    }
    setValues(initValues)
    mutate(`${API_URL}/api/options`)
  }

  const handleSave = async () => {
    if (!values.name.trim()) {
      toast.error("Название опций обязательно")
      return
    }

    if (!Object.keys(values.options).length) {
      toast.error("Не введено ни одной опции")
      return
    }

    const res = await fetch(`${API_URL}/api/options`, {
      method: values._id ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(values),
    })
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
    }
    setValues(initValues)

    mutate(`${API_URL}/api/options`)
  }

  const handle = (rez) => {
    if (rez) {
      handleDelete(optForDelete)
    }
    setOptForDelete({})
    setShowModal(false)
  }

  return (
    <>
      <Helmet>
        <title>Редактировать опции</title>
        <meta
          name="description"
          content="Редактирование опций в машазине Кармен"
        />
      </Helmet>
      {isAdmin ? (
        <div className="px-2">
          {options ? (
            <>
                <Links home={true} >
              <div className="flex justify-end items-center text-3xl">

                <FaSave
                  className="text-sb cursor-pointer hover:text-sb-dark"
                  onClick={handleSave}
                />
              </div>
              </Links>
              <div className="pt-4 grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="max-h-[80vh] overflow-auto border border-sb rounded-md p-1">
                  {options.map((item, i) => (
                    <div
                      className="px-3 py-2 flex items-center gap-3 text-lg odd:bg-gray-200 even:bg-gray-50"
                      key={i}
                    >
                      <p className="flex-1"> {item.name}</p>
                      <FaPencilAlt
                        className="text-sb cursor-pointer text-xl"
                        onClick={() => handleEdit(item)}
                      />
                      <FaTimes
                        className="text-red-500 cursor-pointer text-xl"
                        onClick={() => handleModal(item)}
                      />
                    </div>
                  ))}
                </div>
                <div>
                  <Options values={values} setValues={setValues} />
                </div>
              </div>
            </>
          ) : (
            <Spinner />
          )}
          <ToastContainer />
          {showModal ? (
            <HandleConfirm title1="Удалить опцию?" handle={handle} />
          ) : null}
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  )
}

import React, { useContext, useState } from "react"
import {
  FaBars,
  FaBinoculars,
  FaHome,
  FaMapMarkerAlt,
  FaTimes,
  FaUser,
} from "react-icons/fa"
import { FaArrowRightToBracket } from "react-icons/fa6"
import { useNavigate } from "react-router-dom"

import { Link } from "react-router-dom"
import AuthContext from "../../context/AuthContext"
import { isNotEmpty } from "../../utils"

export default function NavMenu() {
  const { setUser, user } = useContext(AuthContext)
  const navigate = useNavigate()

  const [isShowMainMenu, setIsShowMainMenu] = useState(false)
  const [isShowBurgerMenu, setIsShowBurgerMenu] = useState(false)

  const isUser = isNotEmpty(user)

  const toggleMainUserMenu = () => {
    setIsShowMainMenu((prev) => !prev)
  }

  const toggleBurgerMenu = () => {
    setIsShowBurgerMenu((prev) => !prev)
  }

  return (
    <>
      <nav>
         <ul className="hidden md:flex md:items-center  list-none gap-x-6 text-2xl text-sb">
        <li className="hover:text-sb-dark cursor-pointer" title="Главная">
          <Link to="/">
            <FaHome className="text-3xl" />
          </Link>
        </li>
        <li className="hover:text-sb-dark cursor-pointer" title="Контакты">
          <Link to="/contacts">
            <FaBinoculars />
          </Link>
        </li>
        <li className="hover:text-sb-dark cursor-pointer" title="Карта">
          <Link to="/map">
            <div className="flex items-center gap-x-1">
              <FaMapMarkerAlt />
              {/* <span>Карта</span> */}
            </div>
          </Link>
        </li>
        <li className="relative hover:text-sb-dark cursor-pointer">
          {isUser ? (
            <>
              <FaUser onClick={toggleMainUserMenu} />

              <ul
                className={`${
                  isShowMainMenu ? "block" : "hidden"
                } absolute z-50 overflow-hidden top-[150%] text-lg text-gray-900
                   -right-[10px] w-[250px] bg-[#fbfcfc] border border-[#888] list-none rounded-md shadow-md shadow-black/30 `}
              >
                <li
                  className="py-2 px-5 w-full cursor-auto flex justify-start items-center gap-3 hover:bg-[#ecf0f1]
                  border-sky-300 border-b-2"
                >
                  <>
                    <FaUser className="text-sb"/>
                    <p>{user.userName}</p>
                    <div
                      className="cursor-pointer text-xl text-red-500 ml-auto"
                      onClick={toggleMainUserMenu}
                    >
                      <FaTimes />
                    </div>
                  </>
                </li>
                <li className="py-2 px-5 w-full hover:bg-[#ecf0f1] ">
                  <Link to={`/order_user_list/${user._id}`}>
                    <p onClick={toggleMainUserMenu}>Мои заказы</p>
                  </Link>
                </li>
                <li className="py-2 px-5 w-full hover:bg-[#ecf0f1]">
                  <Link to="/user_profile">
                    <p onClick={toggleMainUserMenu}>Профиль</p>
                  </Link>
                </li>
                <li
                  className="py-2 px-5 w-full hover:bg-[#ecf0f1]"
                  onClick={() => setUser({})}
                >
                  Выйти
                </li>
              </ul>
            </>
          ) : (
            <FaArrowRightToBracket
              onClick={() => {
                setIsShowMainMenu(false)
                navigate("/login")
              }}
              title="Войти"
            />
          )}
        </li>
      </ul>
      </nav>
     

      <div className="md:hidden flex justify-end cursor-pointer relative text-3xl">
        <div onClick={toggleBurgerMenu} className="relative">
          <FaBars />
          {isUser ? (
           <FaUser className="absolute -top-[1px] -right-2 text-sm text-sb"/>
          ):null}         
        </div>

        <ul
          className={` ${
            isShowBurgerMenu ? "block" : "hidden"
          } absolute overflow-hidden text-lg
             z-50 top-[150%] right:auto md:right-0 left-0 md:left-auto w-[250px] bg-[#fbfcfc]
              border border-[#888] list-none rounded-md shadow-md shadow-black/30`}
        >
          <li
            className={`px-5 py-3 w-full cursor-auto ${
              isUser ? "flex" : "hidden"
            } justify-start items-center gap-2 hover:bg-[#ecf0f1] border-sky-300 border-b-2`}
          >
            <FaUser className="text-sb"/>
            <p>{user.userName}</p>
            <div
              className="text-lg text-red-500 ml-auto cursor-pointer"
              onClick={toggleBurgerMenu}
            >
              <FaTimes />
            </div>
          </li>
          <li className="px-5 py-2 w-full hover:bg-[#ecf0f1]">
            <Link to="/">
              <p onClick={toggleBurgerMenu}>Главная</p>
            </Link>
          </li>
          <li className="px-5 py-2 w-full hover:bg-[#ecf0f1]">
            <Link to="/contacts">
              <p onClick={toggleBurgerMenu}>Контакты</p>
            </Link>
          </li>
          <li className="px-5 py-2 w-full hover:bg-[#ecf0f1]">
            <Link to="/map">
              <p onClick={toggleBurgerMenu}>На карте</p>
            </Link>
          </li>
          <li
            className={`${
              !isUser ? "block" : "hidden"
            } px-5 py-1 w-full hover:bg-[#ecf0f1]`}
          >
            <div
              onClick={() => {
                toggleBurgerMenu()
                navigate("/login")
              }}
            >
              <p>Войти</p>
            </div>
          </li>
          <div className={isUser ? "block" : "hidden"}>
            <li className="px-5 py-2 w-full hover:bg-[#ecf0f1]">
              <Link to={`/order_user_list/${user._id}`}>
                <p onClick={toggleBurgerMenu}>Мои заказы</p>
              </Link>
            </li>
            <li className="px-5 py-2 w-full hover:bg-[#ecf0f1]">
              <Link to="/user_profile">
                <p onClick={toggleBurgerMenu}>Профиль</p>
              </Link>
            </li>
            <li
              className="px-5 py-2 w-full hover:bg-[#ecf0f1]"
              onClick={() => {
                toggleBurgerMenu()
                setUser({})
              }}
            >
              Выйти
            </li>
          </div>
        </ul>
      </div>
    </>
  )
}

import Links from "../components/Links"
import AuthContext from "../context/AuthContext"
import ProductsContext from "../context/ProductsContext"
import { useContext, useEffect, useState } from "react"
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { formatingPhone, getQntInCart, getTotalAmount } from "../utils/index"
import { API_URL, LOGO_NP } from "../config"
import { CARRIERS } from "../config/index"
import { Helmet } from "react-helmet-async"

export default function Checkout() {
  const { cart, setCart } = useContext(ProductsContext)
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  const [values, setValues] = useState({
    name: "",
    surname: "",
    phone: "",
    city: "",
    carrier: "",
    branch: "",
    pickup: true,
    prepaid: true,
  })
  const [disable, setDisable] = useState(false)
  const [showCities, setShowCities] = useState(false)
  const [citiesList, setCitiesList] = useState([])
  const [showBranches, setShowBranches] = useState(false)
  const [branchesList, setBranchesList] = useState([])
  const [timeoutId, setTimeoutId] = useState(0)

  useEffect(() => {
    if (Object.keys(user).length) {
      const { name, surname, phone, city, carrier, branch } = user.delivery
      setValues({ ...values, name, surname, phone, city, carrier, branch })
    } else {
      const data = localStorage.getItem("checkout")
      if (data) setValues(JSON.parse(data))
    }
    // установка единственного перевозчика
    setValues((prev) => ({ ...prev, carrier: CARRIERS[0] }))
  }, [user])

  useEffect(() => {
    setDisable(!values.name || !values.phone)
  }, [values])

  useEffect(() => {
    clearTimeout(timeoutId)

    const fetchBranches = async () => {
      const body = {
        apiKey: "c7521ca8867985bf615aa087101f503f",
        modelName: "Address",
        calledMethod: "getWarehouses",
        methodProperties: {
          FindByString: values.branch,
          CityName: values.city,
          Limit: "",
        },
      }
      const res = await fetch("https://api.novaposhta.ua/v2.0/json/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
      const { data } = await res.json()
      const branches = data.map((item) => item.Description)
      setBranchesList(branches)
    }
    setTimeoutId(setTimeout(async () => fetchBranches(), 700))
  }, [values.city, values.branch])

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "delMethod") {
      setValues({
        ...values,
        pickup: value === "pickup",
      })
    } else {
      if (name === "payment") {
        setValues({
          ...values,
          ["prepaid"]: value === "prepaid",
        })
      } else {
        e.preventDefault()
        setValues({ ...values, [name]: value })
      }
    }
  }

  const handleCityChange = async (e) => {
    e.preventDefault()
    const { value } = e.target
    setValues({ ...values, city: value })

    if (value.length >= 2) {
      const body = {
        apiKey: "c7521ca8867985bf615aa087101f503f",
        modelName: "Address",
        calledMethod: "getCities",
        methodProperties: {
          FindByString: value,
          Limit: "",
        },
      }

      const res = await fetch("https://api.novaposhta.ua/v2.0/json/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
      const { data } = await res.json()
      const cities = data.map((item) => item.Description)

      setCitiesList(cities)
      if (cities.length) {
        setShowCities(true)
      }
    } else {
      setCitiesList([])
    }
  }

  const handleBranchChange = async (e) => {
    e.preventDefault()
    const { value } = e.target
    setValues({ ...values, branch: value })
  }

  const handleSendOrder = async () => {
    const totalAmount = getTotalAmount(cart)
    const totalQnt = getQntInCart(cart)
    if (!totalQnt) {
      toast.warning("Заказ пустой, нечего отправлять")
      return
    }
    localStorage.setItem("checkout", JSON.stringify(values))

    const res = await fetch(`${API_URL}/api/order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderItems: cart,
        delivery: values,
        totalQnt,
        totalAmount,
        userId: Object.keys(user).length ? user._id : null,
      }),
    })
    const data = await res.json()
    if (res.ok) {
      toast.success("Заказ обработан успешно")
      setCart([])
      setDisable(true)
      navigate("/")
    } else {
      toast.error(data.message)
    }
  }

  return (
    <>
      <Helmet>
        <title>Форма заказа</title>
        <meta
          name="description"
          content="Форма заказа и данные покупателя в машазине Кармен"
        />
      </Helmet>
      <ToastContainer />
      <div className=" h-full">
        <Links home={true} back={true}>
          <div className="flex items-center justify-end">
            <div className="p-0.5 border-2 border-sb rounded-md">
              <button
                className="text-base text-gray-50   bg-sb shadow-inner rounded-md px-3 py-1
               tracking-wide cursor-pointer disabled:opacity-50 disabled:cursor-auto"
                onClick={handleSendOrder}
                disabled={disable}
              >
                Отправить заказ
              </button>
            </div>
          </div>
        </Links>
        <div className="w-full h-[calc(100%-40px)] flex items-center justify-center">
          <div
            className="  md:w-[70%] lg:w-[40%]  border
              border-gray-500 p-3 bg-[#FDFEFE] shadow-md"
          >
            <div className="py-2 flex flex-col gap-2 justify-center items-stretch text-lg">
              <div>
                <label htmlFor="name">Имя получателя</label>
                <input
                  className="block w-full px-2 h-8 my-1"
                  type="text"
                  id="name"
                  name="name"
                  maxLength="30"
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="surname">Фамилия получателя</label>
                <input
                  className="block w-full px-2 h-8 my-1"
                  type="text"
                  id="surname"
                  name="surname"
                  maxLength="30"
                  value={values.surname}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="phone">Телефон</label>
                <input
                  className="block w-full px-2 h-8 my-1"
                  type="text"
                  id="phone"
                  name="phone"
                  maxLength="15"
                  value={values.phone}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      phone: formatingPhone(e.target.value),
                    })
                  }
                />
              </div>

              <div>
                <p> Способ доставки</p>
                <div className="flex justify-between">
                  <div className="flex items-center px-1 gap-x-3 my-2 ">
                    <label htmlFor="pickup">Самовывоз</label>
                    <input
                      className="w-5 h-5"
                      type="radio"
                      id="pickup"
                      name="delMethod"
                      value="pickup"
                      checked={values.pickup}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex items-center px-1 gap-x-3 my-2 ">
                    <label htmlFor="courier">Перевозчиком</label>
                    <input
                      className="w-5 h-5"
                      type="radio"
                      id="courier"
                      name="delMethod"
                      value="courier"
                      checked={!values.pickup}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              {!values.pickup ? (
                <>
                  <div>
                    <div className="flex items-center gap-2  text-xl">
                      <img src={LOGO_NP} className="w-6 h-6" alt="" />
                      <p>Новая почта</p>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="city">Населенный пункт</label>
                    <div className="relative">
                      <input
                        className="block w-full px-2 h-8 my-1"
                        type="text"
                        name="city"
                        maxLength="20"
                        value={values.city}
                        onChange={handleCityChange}
                        onFocus={() => setShowCities(true)}
                        onBlur={() =>
                          setTimeout(() => setShowCities(false), 300)
                        }
                      />
                      <div
                        className={`droplist_wrapper ${
                          showCities ? "block" : "hidden"
                        }`}
                      >
                        {citiesList.length ? (
                          <ul className="checkout_droplist">
                            {citiesList.map((item, i) => (
                              <li
                                key={i}
                                className="px-2 cursor-pointer hover:bg-gray-100"
                                onClick={() =>
                                  setValues({ ...values, city: item })
                                }
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="branch">Отделение</label>
                    <div className="relative">
                      <input
                        className="block w-full px-2 h-8 my-1"
                        type="text"
                        name="branch"
                        value={values.branch}
                        onChange={handleBranchChange}
                        onFocus={() => setShowBranches(true)}
                        onBlur={() =>
                          setTimeout(() => setShowBranches(false), 300)
                        }
                      />
                      <div
                        className={`droplist_wrapper ${
                          showBranches ? "block" : "hidden"
                        }`}
                      >
                        {branchesList.length ? (
                          <ul className="checkout_droplist">
                            {branchesList.map((item, i) => (
                              <li
                                key={i}
                                className="px-2 cursor-pointer hover:bg-gray-100"
                                onClick={() =>
                                  setValues({ ...values, branch: item })
                                }
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    <p> Форма оплаты</p>
                    <div className="flex justify-between">
                      <div className="flex items-center px-1 gap-x-3 my-2 ">
                        <label htmlFor="prepaid">Предоплата</label>
                        <input
                          className="w-5 h-5"
                          type="radio"
                          id="prepaid"
                          name="payment"
                          value="prepaid"
                          checked={values.prepaid}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="flex items-center px-1 gap-x-3 my-2 ">
                        <label htmlFor="postpaid">Наложенный платеж</label>
                        <input
                          className="w-5 h-5"
                          type="radio"
                          id="postpaid"
                          name="payment"
                          value="postpaid"
                          checked={!values.prepaid}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
